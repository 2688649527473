@font-face {
  font-family: 'Chakra Petch';
  src: local('Chakra Petch'),
       url(./fonts/ChakraPetch-Regular.ttf) format('truetype');
}
.container {
    width: 351px;
    height: 293px;
    background: #0F0F0F;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
}

.main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    line-height: 1.15;
    font-size: 3rem;
    text-align: center;
    margin: 50px;
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.console {
    max-width: 100%;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
                 Bitstream Vera Sans Mono, Courier New, monospace;
    overflow-wrap: break-word;
}

.grid {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.setting {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 30px 0;
    background: #f4f5f7;
    border: 1px solid #ebecf0;
    border-radius: 5px;
}

.card {
    margin: 0.5rem;
    padding: 0.7rem;
    text-align: center;
    color: #0070f3;
    text-decoration: none;
    border: 1px solid #0070f3;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 100%;
}

.input {
    margin: 0.5rem;
    padding: 0.7rem;
    text-align: center;
    color: #0070f3;
    text-decoration: none;
    border: 1px solid #0070f3;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    width: 100%;
}


.row {
    display: flex;
    margin-bottom: 0.5rem;
    width: 100%;
}
.label {
    flex-basis: 45%;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-weight: bold;
}

.select {
    flex-basis: 30%;
    text-align: left;
    padding: 0.3rem 0.8rem;
    font-size: 1rem;
    border-radius: 8px;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26.39px;
}

.footer_normal_text {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 600;
    font-size: 14.109px;
    color: #535353;
    line-height: 18px;
}

.footer_big_text {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 700;
    font-size: 16.1351px;
    line-height: 21px;
    color: #535353;
}

.logo {
    height: 1.5rem;
    margin-left: 0.5rem;
}

.centerFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: 30px 0; */
    background: #f4f5f7;
    border: 1px solid #ebecf0;
    border-radius: 5px;
    padding: 30px;
}

@media (max-width: 1200px) {
    .container {
        width: 100%;
    }
}


.header_text {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 110%;    
    display: flex;
    align-items: center;
    text-align: center;    
    color: #FFFFFF;
    border: 1px solid #000000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.google_card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 319px;
    height: 45px;   
    background: #252525;
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

.button_text_span {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}


.twitch_card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 319px;
    height: 45px;   
    background: linear-gradient(95.39deg, #001AFF 3.78%, #DB00FF 100%);
    border-radius: 12px;
    border: none;
    cursor: pointer;
}
